<div class="layout-breadcrumb-container">
    <nav class="layout-breadcrumb">
        <ol>
            <li><i class="pi pi-home"></i></li>
            <ng-template ngFor let-item let-last="last" [ngForOf]="breadcrumbs$ | async">
                <li><i class="pi pi-angle-right"></i></li>
                <li><span>{{item.label}}</span></li>
            </ng-template>
        </ol>
    </nav>
    <div class="notification">👋 Hallo {{ currentUser?.firstName }}, er zijn geen berichten voor jou vandaag</div>
</div>

<div class="h-screen flex flex-column bg-cover" style="background: url('assets/layout/images/pages/login-bg-2.jpeg')">
  <div class=" shadow-2 tw-bg-indigo-900 z-5 p-3 flex justify-content-between flex-row align-items-center">
    <div class="ml-3 flex" routerLink="/">
      <div>
        <img class="h-2rem" src="assets/layout/images/logo.png" alt="" />
      </div>
    </div>
    <div class="mr-3 flex">
      <button pButton pRipple type="button" label="DASHBOARD" [routerLink]="['/']"
              class="p-button-text p-button-plain text-white"></button>
    </div>
  </div>

  <div class="align-self-center mt-auto mb-auto">
    <div class="text-center z-5 flex flex-column border-1 border-round-md surface-border surface-card px-3">

      <h4 class="my-2">Welcome</h4>

      <div class="text-color-secondary mb-6 px-6">Please use the form to sign-in into The NextGen Raes network</div>

      <div class="w-full flex flex-column gap-3 px-3 pb-6">
                <span class="p-input-icon-left">
                    <i class="pi pi-envelope"></i>
                    <input type="text" class="w-full" pInputText placeholder="E-mail" />
                </span>

        <span class="p-input-icon-left">
                    <i class="pi pi-key"></i>
                    <input type="password" class="w-full" pInputText placeholder="Password" />
                </span>
        <button pButton pRipple class="w-full my-3 px-3" label="LOGIN" (click)="login()"></button>
      </div>
    </div>
  </div>
</div>

import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Soil} from '@shared/model/soil';
import {Disease} from '@shared/model/disease';
import {Fertilization} from '@shared/model/fertilization';
import {Stand} from '@shared/model/stand';
import {Lifetime} from '@shared/model/lifetime';
import {Subscription} from 'rxjs';
import {OptionService} from '@core/services/option.service';
import {PropertiesService} from '@core/http/properties.service';
import {FlowerMonth} from '@shared/model/flowerMonth';

@Component({
  selector: 'app-genus-specie-properties-tab',
  templateUrl: './genus-specie-properties-tab.component.html',
  styleUrls: ['./genus-specie-properties-tab.component.css']
})
export class GenusSpeciePropertiesTabComponent implements OnInit {
  @Input() form: FormGroup;

  public fertilizations: Fertilization[] = [];
  public diseases: Disease[] = [];
  public soils: Soil[] = [];

  public stands: Stand[] = [];
  public months: FlowerMonth[] = [];

  subscriptions: Subscription[] = [];

  constructor(private optionService: OptionService,
              private propertiesService: PropertiesService) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.optionService.getStands().subscribe(stands => this.stands = stands),
      this.optionService.getFlowerMonths().subscribe(months => this.months = months),
      this.optionService.getFertilizations().subscribe(fertilizations => this.fertilizations = fertilizations),
      this.propertiesService.retrieveDiseases().subscribe(diseases => this.diseases = diseases),
      this.propertiesService.retrieveSoils().subscribe(soils => this.soils = soils),
    );
  }


  public soilLabel(option: Soil): string {
    return option.nameNl;
  }

  public soilValue(option: Soil): string {
    return option.id;
  }

  public diseaseLabel(option: Disease): string {
    return option.nameNl;
  }

  public diseaseValue(option: Disease): string {
    return option.id;
  }

}

import {Component, Input, OnInit, Optional, Self} from '@angular/core';
import {AbstractControlDirective} from "@shared/directives/abstract-control.directive";
import {InputType} from '@shared/components/input/input-type.enum';
import {AbstractControl, FormControl, NgControl} from '@angular/forms';
import {ValidationUtil} from '@shared/validation/validation-util.service';

@Component({
  selector: 'app-tristate',
  templateUrl: './tristate.component.html',
  styleUrls: ['./tristate.component.css']
})
export class TristateComponent extends AbstractControlDirective {
  @Input() label: string;
  @Input() orientation: InputType = InputType.VERTICAL;

  constructor(@Self() @Optional() public ngControl: NgControl,
              private validationUtil: ValidationUtil) {
    super(ngControl);
  }

  get required(): boolean {
    if (this.control.validator) {
      const validator = this.control.validator({} as AbstractControl);

      return validator && validator.required;
    }

    return false;
  }

  isVertical(): boolean {
    return this.orientation === InputType.VERTICAL;
  }

  get hasErrors(): boolean {
    return this.control.dirty && !this.control.valid;
  }

  get error(): string {
    if (this.hasErrors && this.control?.errors !== null && Object.keys(this.control.errors).length !== 0) {
      const firstError = Object.keys(this.control.errors)[0];
      return this.validationUtil.handleError(firstError, this.control.errors[firstError]);
    }
  }
}

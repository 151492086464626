import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AppConfigService} from '@core/providers/app-config.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {UserPreferences, UserProfile} from '@shared/model/auth/user-profile';
import {User} from '@shared/model/user';
import {Page} from '@shared/model/page';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient,
              private configProvider: AppConfigService) {}

  public fetchUserProfile(): Observable<UserProfile> {
    return this.http.get(`${this.configProvider.config().privateApiBase}/user-info`).pipe(
      map(UserProfile.adopt)
    );
  }

  public updatePreferences(preferences: UserPreferences): Observable<any> {
    return this.http.put(`${this.configProvider.config().privateApiBase}/raesusers/preferences`, preferences);
  }

  readUser(userId: number): Observable<User> {
    const userUrl = `${this.configProvider.config().privateApiBase}/raesusers/${userId}`;
    return this.http.get<User>(userUrl).pipe(
      map(User.adapt)
    );
  }

  editUser(userId: number, user: User): Observable<number> {
    const userUrl = `${this.configProvider.config().privateApiBase}/raesusers/${userId}`;

    return this.http.put<any>(userUrl, user).pipe(
      map(response => response.userId)
    );
  }

  readUsers(): Observable<Page<User>> {
    const userUrl = `${this.configProvider.config().privateApiBase}/raesusers`;
    return this.http.get<Page<User>>(userUrl).pipe(
      map(page => Page.adapt(page, page.content.map(User.adapt)))

    );
  }

  retrieve(searchParameters: UserSearchParameters): Observable<Page<User>> {
    const userUrl = `${this.configProvider.config().privateApiBase}/raesusers`;
    return this.http.get<Page<User>>(userUrl, { params: searchParameters.build() }).pipe(
      map(page => Page.adapt(page, page.content.map(User.adapt)))

    );
  }

  createUser(user: User): Observable<number> {
    const userUrl = `${this.configProvider.config().privateApiBase}/raesusers`;
    return this.http.post<any>(userUrl, user);
  }
}

export class UserSearchParameters {
  constructor(
    public name: string = '',
    public customerId: number = null,
    public page: number = null,
    public size: number = null,
  ) {
  }
  build(): HttpParams {
    let params = new HttpParams();

    if (this.name) { params = params.append('customerName', this.name); }
    if (this.customerId) { params = params.append('customerId', String(this.customerId)); }
    if (this.page) { params = params.append('page' , String(this.page - 1)); }
    if (this.size) { params = params.append('size' , String(this.size)); }
    params = params.append('sort', 'company,asc');

    return params;
  }
}

<div class="layout-footer">
  <img [src]="'assets/layout/images/logo.png'" alt="Raes Bloemzaden nv">
  <div class="text">
    <h1>Raes Bloemzaden nv</h1>
    <span>NextGen Ground - Applications Management</span>
    <span class="pl-2">{{ buildInfo }}</span>
  </div>
    <div class="flex gap-2">
        <button pButton pRipple type="button" icon="pi pi-home" class="p-button-rounded p-button-text p-button-plain"  (click)="navigateToDashboard()"></button>
        <button pButton pRipple type="button" icon="pi pi-facebook" class="p-button-rounded p-button-text p-button-plain" (click)="navigateToFacebook()"></button>
        <button pButton pRipple type="button" icon="pi pi-globe" class="p-button-rounded p-button-text p-button-plain" (click)="navigateToWebsite()"></button>
    </div>
</div>

<app-card [title]="'Vertalingen'">
  <div class="grid">

    <div class="col-12" [formGroup]="form">
      <h1>Te vertalen tekst (kies taal via dropdown)</h1>
      <div class="p-inputgroup tw-my-5" [formGroup]="translateCriteria">
         <textarea pInputTextarea class="tw-rounded"
                   [autoResize]="true"
                   formControlName="translatableDescription"
         ></textarea>
        <span class="p-inputgroup-addon"> <p-dropdown class="p-inputgroup-addon"
                                                      [options]="['NL', 'FR', 'EN', 'DE']"
                                                      formControlName="sourceLanguage">
        </p-dropdown></span>
      </div>
      <p-accordion>
        <p-accordionTab header="{{'common.card.translations.nl' | translate }}" [selected]="true">
          <div class="grid tw-mb-5">
            <app-text class="col-12" label="{{'common.card.translations.name' | translate }}"
                      formControlName="nameNl"></app-text>
          </div>
          <div class="grid">
            <app-text-area class="col-12" [rows]="2" autoResize="true"
                           label="{{'common.card.translations.description.general' | translate }}"
                           formControlName="genericDescriptionNl"></app-text-area>
            <app-text-area class="col-12" [rows]="2" autoResize="true"
                           label="{{'common.card.translations.description.seeds' | translate }}"
                           formControlName="seedsDescriptionNl"></app-text-area>
            <app-text-area class="col-12" [rows]="2" autoResize="true"
                           label="{{'common.card.translations.description.cuttings' | translate }}"
                           formControlName="cuttingsDescriptionNl"></app-text-area>
          </div>
          <h1>Voorgestelde vertaling (naar het Nederlands</h1>
          <app-suggestion
            class="col-12 p-md-4"
            [sourceLanguage]="translateCriteria.controls.sourceLanguage.value"
            targetLanguage="NL"
            [expanded]="true"
            [disableSelect]="true"
            [change]="reloadSuggestions"
          ></app-suggestion>
        </p-accordionTab>
        <p-accordionTab header="{{'common.card.translations.en' | translate }}" *ngIf="editMode">
          <div class="grid">
            <app-text class="col-12 tw-mb-5" label="{{'common.card.translations.name' | translate }}"
                      formControlName="nameEn"></app-text>
          </div>
          <div class="grid">
            <app-text-area class="col-12" [rows]="2" autoResize="true"
                           label="{{'common.card.translations.description.general' | translate }}"
                           formControlName="genericDescriptionEn"></app-text-area>
            <app-text-area class="col-12" [rows]="2" autoResize="true"
                           label="{{'common.card.translations.description.seeds' | translate }}"
                           formControlName="seedsDescriptionEn"></app-text-area>
            <app-text-area class="col-12" [rows]="2" autoResize="true"
                           label="{{'common.card.translations.description.cuttings' | translate }}"
                           formControlName="cuttingsDescriptionEn"></app-text-area>
          </div>
          <app-suggestion
            class="col-12 p-md-4"
            [sourceLanguage]="translateCriteria.controls.sourceLanguage.value"
            targetLanguage="EN"
            [expanded]="true"
            [disableSelect]="true"
            [change]="reloadSuggestions"
          ></app-suggestion>
        </p-accordionTab>
        <p-accordionTab header="{{'common.card.translations.fr' | translate }}" *ngIf="editMode">
          <div class="grid">
            <app-text class="col-12 tw-mb-5" label="{{'common.card.translations.name' | translate }}"
                      formControlName="nameFr"></app-text>
          </div>
          <div class="grid">
            <app-text-area disabled="true" class="col-12" [rows]="2" autoResize="true"
                           label="{{'common.card.translations.description.general' | translate }}"
                           formControlName="genericDescriptionFr"></app-text-area>
            <app-text-area disabled="true" class="col-12" [rows]="2" autoResize="true"
                           label="{{'common.card.translations.description.seeds' | translate }}"
                           formControlName="seedsDescriptionFr"></app-text-area>
            <app-text-area disabled="true" class="col-12" [rows]="2" autoResize="true"
                           label="{{'common.card.translations.description.cuttings' | translate }}"
                           formControlName="cuttingsDescriptionFr"></app-text-area>
          </div>
          <app-suggestion
            class="col-12 p-md-4"
            [sourceLanguage]="translateCriteria.controls.sourceLanguage.value"
            targetLanguage="FR"
            [expanded]="true"
            [disableSelect]="true"
            [change]="reloadSuggestions"
          ></app-suggestion>
        </p-accordionTab>
        <p-accordionTab header="{{'common.card.translations.de' | translate }}" *ngIf="editMode">
          <div class="grid">
            <app-text class="col-12 tw-mb-5" label="{{'common.card.translations.name' | translate }}"
                      formControlName="nameDe"></app-text>
          </div>
          <div class="grid">
            <app-text-area class="col-12" [rows]="2" autoResize="true"
                           label="{{'common.card.translations.description.general' | translate }}"
                           formControlName="genericDescriptionDe"></app-text-area>
            <app-text-area class="col-12" [rows]="2" autoResize="true"
                           label="{{'common.card.translations.description.seeds' | translate }}"
                           formControlName="seedsDescriptionDe"></app-text-area>
            <app-text-area class="col-12" [rows]="2" autoResize="true"
                           label="{{'common.card.translations.description.cuttings' | translate }}"
                           formControlName="cuttingsDescriptionDe"></app-text-area>
          </div>
          <app-suggestion
            class="col-12 p-md-4"
            [sourceLanguage]="translateCriteria.controls.sourceLanguage.value"
            targetLanguage="DE"
            [expanded]="true"
            [disableSelect]="true"
            [change]="reloadSuggestions"
          ></app-suggestion>
        </p-accordionTab>
        <p-accordionTab header="{{'common.card.translations.hu' | translate }}" *ngIf="editMode">
          <div class="grid">
            <app-text class="col-12 tw-mb-5" label="{{'common.card.translations.name' | translate }}"
                      formControlName="nameHu"></app-text>
          </div>
          <div class="grid">
            <app-text-area class="col-12" [rows]="2" autoResize="true"
                           label="{{'common.card.translations.description.general' | translate }}"
                           formControlName="genericDescriptionHu"></app-text-area>
            <app-text-area class="col-12" [rows]="2" autoResize="true"
                           label="{{'common.card.translations.description.seeds' | translate }}"
                           formControlName="seedsDescriptionHu"></app-text-area>
            <app-text-area class="col-12" [rows]="2" autoResize="true"
                           label="{{'common.card.translations.description.cuttings' | translate }}"
                           formControlName="cuttingsDescriptionHu"></app-text-area>
          </div>
        </p-accordionTab>
        <p-accordionTab header="{{'common.card.translations.pl' | translate }}" *ngIf="editMode">
          <div class="grid">
            <app-text class="col-12 tw-mb-5" label="{{'common.card.translations.name' | translate }}"
                      formControlName="namePl"></app-text>
          </div>
          <div class="grid">
            <app-text-area class="col-12" [rows]="2" [autoResize]="true"
                           label="{{'common.card.translations.description.general' | translate }}"
                           formControlName="genericDescriptionPl"></app-text-area>
            <app-text-area class="col-12" [rows]="2" [autoResize]="true"
                           label="{{'common.card.translations.description.seeds' | translate }}"
                           formControlName="seedsDescriptionPl"></app-text-area>
            <app-text-area class="col-12" [rows]="2" [autoResize]="true"
                           label="{{'common.card.translations.description.cuttings' | translate }}"
                           formControlName="cuttingsDescriptionPl"></app-text-area>
          </div>
        </p-accordionTab>
      </p-accordion>
    </div>
  </div>
</app-card>



export const appRoutesNames = {
  HOME: '',
  ERROR: 'error',
  LOGIN: 'login',
  ACCOUNT: 'account',
  CATALOG: 'catalog',
  AVAILABILITY: 'availability',
  DIGITALCATALOG: 'digitalcatalog',
  XMLCATALOG: 'xmlcatalog',
  ORDERFORM: 'orderform',
  JOB: 'job',
  JOBAPPLY: 'jobapply',
  NEWSITEM: 'newsitem',
  CONTACTINFO: 'contactinfo',
  TAXONOMY: 'taxonomy',
  IMAGEDB: 'imagedb',
  USER: 'user',
  CUSTOMER: 'customer',
  TRANSLATION: 'translation',
  ORDER: 'order'
};

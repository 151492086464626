import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {CoreModule} from '@core/core.module';
import {SharedModule} from '@shared/shared.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {APP_ROUTES} from './app.routes';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppConfigService} from '@core/providers/app-config.service';
import {OAuthModule} from 'angular-oauth2-oidc';
import {OauthInterceptor} from '@core/interceptors/oauth.interceptor';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {AppLayoutModule} from '@app/layout/app.layout.module';
import {FileSaverModule} from 'ngx-filesaver';
import {MessageService} from 'primeng/api';
import {MessageModule} from 'primeng/message';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function initConfig(appConfig: AppConfigService) {
  return () => appConfig.loadConfig();
}

@NgModule({ declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppLayoutModule,
        CoreModule,
        SharedModule,
        FileSaverModule,
        BrowserAnimationsModule,
        OAuthModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        RouterModule.forRoot(APP_ROUTES, {
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled'
        }),
        ConfirmDialogModule], providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initConfig,
            deps: [AppConfigService],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: OauthInterceptor,
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {
  constructor() {
  }
}

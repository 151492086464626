<div class="layout-topbar" [ngClass]="{'tw-bg-yellow-400': env === 'dev' || env ==='test'}">
  <div class="layout-topbar-start">
    <a href="#" class="layout-topbar-logo">
      <img src="assets/layout/images/logo.png" alt="Raes Bloemzaden nv"/>
    </a>
    <a #menuButton class="layout-menu-button" (click)="onMenuButtonClick()" pRipple>
      <i class="pi pi-chevron-right"></i>
    </a>
    <a #mobileMenuButton class="layout-topbar-mobile-button" (click)="onMobileTopbarMenuButtonClick()" pRipple>
      <i class="pi pi-ellipsis-v"></i>
    </a>
  </div>

  <div *ngIf="env === 'dev' || env === 'test'" class="tw-fixed tw-h-5 tw-w-full tw-text-center">
    <div class="tw-w-400 tw-h-5 tw-inline-block tw-pt-5">
      <p><strong class="tw-text-red-600"> THIS IS A TEST ENVIRONMENT - DO NOT SUBMIT REAL DATA ! </strong></p>
    </div>
  </div>

  <div class="layout-topbar-end">
    <div class="layout-topbar-actions-start">
      <p-megaMenu [model]="model" styleClass="layout-megamenu"></p-megaMenu>
    </div>
    <div class="layout-topbar-actions-end">
      <ul class="layout-topbar-items">
        <li>
          <a pStyleClass="@next" enterClass="ng-hidden" enterActiveClass="px-scalein" leaveToClass="ng-hidden"
             leaveActiveClass="px-fadeout" [hideOnOutsideClick]="true" pRipple>
            <img src="assets/demo/images/avatar/amyelsner.png" alt="{{ currentUser?.fullName }}" class="w-2rem h-2rem">
          </a>
          <div class="ng-hidden">
            <ul class="list-none p-0 m-0">
              <li>
                <a (click)="navigateToProfile()"
                   class="cursor-pointer flex align-items-center py-2 hover:surface-hover transition-duration-150 transition-all px-3 py-2"
                   pRipple>
                  <i class="pi pi-cog mr-2"></i>
                  <span>Settings</span>
                </a>
              </li>
              <li>
                <a (click)="logout()"
                   class="cursor-pointer flex align-items-center py-2 hover:surface-hover transition-duration-150 transition-all px-3 py-2"
                   pRipple>
                  <i class="pi pi-power-off mr-2"></i>
                  <span>Logout</span>
                </a>
              </li>
            </ul>
          </div>

        </li>
      </ul>
    </div>
  </div>
</div>


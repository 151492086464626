<app-card [title]="'Basis'" [formGroup]="form">
  <div class="grid">
    <app-multi-select
      class="col-12 lg:col-4 md-6"
      formControlName="stands"
      emptyFilterMessage="Selecteer een standplaats"
      [showClear]="true"
      [placeholder]="'taxonomy.genus.properties.stand.select' | translate"
      [options]="stands"
      [label]="'taxonomy.genus.properties.stand.title' | translate">
    </app-multi-select>
    <app-multi-select
      class="col-12 lg:col-4 md-6"
      formControlName="soils"
      [options]="soils"
      [showClear]="true"
      [optionLabel]="soilLabel"
      [placeholder]="'taxonomy.genus.properties.soil.select' | translate"
      [optionValue]="soilValue"
      [label]="'taxonomy.genus.properties.soil.title' | translate">
    </app-multi-select>
    <app-multi-select
      class="col-12 lg:col-4 md-6"
      formControlName="diseases"
      [optionLabel]="diseaseLabel"
      [optionValue]="diseaseValue"
      [showClear]="true"
      [placeholder]="'taxonomy.genus.properties.disease.select' | translate"
      [options]="diseases | orderBy: 'nameNl'"
      [label]="'taxonomy.genus.properties.disease.title' | translate">
    </app-multi-select>
    <app-dropdown
      class="col-12 lg:col-4 md-6"
      formControlName="fertilization"
      [showClear]="true"
      label="{{'taxonomy.genus.properties.fertilization.title' | translate }}"
      placeholder="{{'taxonomy.genus.properties.fertilization.select' | translate }}"
      [options]="fertilizations">
    </app-dropdown>
    <app-text class="col-2" label="{{'taxonomy.genus.properties.fromSowingTemperature' | translate }}"
              formControlName="fromSowingTemperature"></app-text>
    <app-text class="col-2" label="{{'taxonomy.genus.properties.tillSowingTemperature' | translate }}"
              formControlName="tillSowingTemperature"></app-text>
    <app-text class="col-2" label="{{'taxonomy.genus.properties.fromPottingTemperature' | translate }}"
              formControlName="fromPottingTemperature"></app-text>
    <app-text class="col-2" label="{{'taxonomy.genus.properties.tillPottingTemperature' | translate }}"
              formControlName="tillPottingTemperature"></app-text>
  </div>
</app-card>

<app-card [title]="'Zaaiperiode'" class="col-12" [formGroup]="form">
  <div class="grid">
    <app-dropdown class="col-12 lg:col-6 md-3"
                  formControlName="fromSowingMonth"
                  label="{{'taxonomy.genus.properties.fromSowingMonth.label' | translate }}"
                  placeholder="{{'taxonomy.genus.properties.fromSowingMonth.select' | translate }}"
                  [showClear]="true"
                  [options]="months">
    </app-dropdown>

    <app-dropdown class="col-12 lg:col-6 md-3"
                  formControlName="tillSowingMonth"
                  label="{{'taxonomy.genus.properties.tillSowingMonth.label' | translate }}"
                  placeholder="{{'taxonomy.genus.properties.tillSowingMonth.select' | translate }}"
                  [showClear]="true"
                  [options]="months">
    </app-dropdown>

    <app-dropdown class="col-12 lg:col-6 md-3"
                  formControlName="fromSowingSecondMonth"
                  label="{{'taxonomy.genus.properties.fromSowingSecondMonth.label' | translate }}"
                  placeholder="{{'taxonomy.genus.properties.fromSowingSecondMonth.select' | translate }}"
                  [showClear]="true"
                  [options]="months">
    </app-dropdown>

    <app-dropdown class="col-12 lg:col-6 md-3"
                  formControlName="tillSowingSecondMonth"
                  label="{{'taxonomy.genus.properties.tillSowingSecondMonth.label' | translate }}"
                  placeholder="{{'taxonomy.genus.properties.tillSowingSecondMonth.select' | translate }}"
                  [showClear]="true"
                  [options]="months">
    </app-dropdown>

  </div>
</app-card>
<app-card [title]="'Potperiode'" class="col-12" [formGroup]="form">
  <div class="grid">
    <app-dropdown class="col-12 lg:col-6 md-3"
                  formControlName="fromPottingMonth"
                  label="{{'taxonomy.genus.properties.fromPottingMonth.label' | translate }}"
                  placeholder="{{'taxonomy.genus.properties.fromPottingMonth.select' | translate }}"
                  [showClear]="true"
                  [options]="months">
    </app-dropdown>

    <app-dropdown class="col-12 lg:col-6 md-3"
                  formControlName="tillPottingMonth"
                  label="{{'taxonomy.genus.properties.tillPottingMonth.label' | translate }}"
                  placeholder="{{'taxonomy.genus.properties.tillPottingMonth.select' | translate }}"
                  [showClear]="true"
                  [options]="months">
    </app-dropdown>

    <app-dropdown class="col-12 lg:col-6 md-3"
                  formControlName="fromPottingSecondMonth"
                  label="{{'taxonomy.genus.properties.fromPottingSecondMonth.label' | translate }}"
                  placeholder="{{'taxonomy.genus.properties.fromPottingSecondMonth.select' | translate }}"
                  [showClear]="true"
                  [options]="months">
    </app-dropdown>

    <app-dropdown class="col-12 lg:col-6 md-3"
                  formControlName="tillPottingSecondMonth"
                  label="{{'taxonomy.genus.properties.tillPottingSecondMonth.label' | translate }}"
                  placeholder="{{'taxonomy.genus.properties.tillPottingSecondMonth.select' | translate }}"
                  [showClear]="true"
                  [options]="months">
    </app-dropdown>

  </div>
</app-card>
<app-card [title]="'Verkoopsperiode'" class="col-12" [formGroup]="form">
  <div class="grid">
    <app-dropdown class="col-12 lg:col-6 md-3"
                  formControlName="fromSalesMonth"
                  label="{{'taxonomy.genus.properties.fromSalesMonth.label' | translate }}"
                  placeholder="{{'taxonomy.genus.properties.fromSalesMonth.select' | translate }}"
                  [showClear]="true"
                  [options]="months">
    </app-dropdown>
    <app-dropdown class="col-12 lg:col-6 md-3"
                  formControlName="tillSalesMonth"
                  label="{{'taxonomy.genus.properties.tillSalesMonth.label' | translate }}"
                  placeholder="{{'taxonomy.genus.properties.tillSalesMonth.select' | translate }}"
                  [showClear]="true"
                  [options]="months">
    </app-dropdown>
    <app-dropdown class="col-12 lg:col-6 md-3"
                  formControlName="fromSalesSecondMonth"
                  label="{{'taxonomy.genus.properties.fromSalesSecondMonth.label' | translate }}"
                  placeholder="{{'taxonomy.genus.properties.fromSalesSecondMonth.select' | translate }}"
                  [showClear]="true"
                  [options]="months">
    </app-dropdown>
    <app-dropdown class="col-12 lg:col-6 md-3"
                  formControlName="tillSalesSecondMonth"
                  label="{{'taxonomy.genus.properties.tillSalesSecondMonth.label' | translate }}"
                  placeholder="{{'taxonomy.genus.properties.tillSalesSecondMonth.select' | translate }}"
                  [showClear]="true"
                  [options]="months">
    </app-dropdown>
  </div>
</app-card>

  <ng-container [formGroup]="form">


  </ng-container>

import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {MenuOrientation} from '@shared/model/layout/menu-orientation';
import {Language} from '@shared/model/language';
import {Lifetime} from '@shared/model/lifetime';
import {Stand} from '@shared/model/stand';
import {Fertilization} from '@shared/model/fertilization';
import {Usage} from '@shared/model/usage';
import {ProductType} from '@shared/model/productType';
import {ProductCategory} from '@shared/model/productCategory';
import {Trademark} from '@shared/model/trademark';
import {Flowerperiod} from '@shared/model/flowerPeriod';
import {Flowerpattern} from '@shared/model/flowerPattern';
import {FruitMaturity} from '@shared/model/fruitMaturity';
import {FruitColor} from '@shared/model/fruitColor';
import {FruitShape} from '@shared/model/fruitShape';
import {Department} from '@shared/model/department';
import {FlowerMonth} from '@shared/model/flowerMonth';
import {NewsType} from '@shared/model/newsType';
import {Action} from '@shared/model/action';
import {Spiciness} from '@shared/model/spiciness';

@Injectable({
  providedIn: 'root'
})
export class OptionService {
  private menuOrientationsSubject = new ReplaySubject(1);
  private menuOrientationsObservable = this.menuOrientationsSubject.asObservable();

  private languagesSubject = new ReplaySubject(1);
  private languagesObservable = this.languagesSubject.asObservable();

  private lifetimeSubject = new ReplaySubject(1);
  private lifetimeObservable = this.lifetimeSubject.asObservable();

  private standSubject = new ReplaySubject(1);
  private standObservable = this.standSubject.asObservable();

  private fertilizationSubject = new ReplaySubject(1);
  private fertilizationObservable = this.fertilizationSubject.asObservable();

  private usageSubject = new ReplaySubject(1);
  private usageObservable = this.usageSubject.asObservable();

  private productTypeSubject = new ReplaySubject(1);
  private productTypesObservable$ = this.productTypeSubject.asObservable();

  private productCategorySubject = new ReplaySubject(1);
  private productCategoryObservable$ = this.productCategorySubject.asObservable();

  private actionSubject = new ReplaySubject(1);
  private actionObservable$ = this.actionSubject.asObservable();

  private newsTypeSubject = new ReplaySubject(1);
  private newsTypeObservable$ = this.newsTypeSubject.asObservable();

  private spicinessSubject = new ReplaySubject(1);
  private spicinessObservable$ = this.spicinessSubject.asObservable();

  private trademarkSubject = new ReplaySubject(1);
  private trademarkObservable$ = this.trademarkSubject.asObservable();

  private flowerpatternSubject = new ReplaySubject(1);
  private flowerpatternObservable$ = this.flowerpatternSubject.asObservable();
  private flowerperiodSubject = new ReplaySubject(1);
  private flowerperiodObservable$ = this.flowerperiodSubject.asObservable();
  private flowermonthSubject = new ReplaySubject(1);
  private flowermonthObservable$ = this.flowermonthSubject.asObservable();
  private fruitShapeSubject = new ReplaySubject(1);
  private fruitShapeObservable$ = this.fruitShapeSubject.asObservable();
  private fruitColorSubject = new ReplaySubject(1);
  private fruitColorObservable$ = this.fruitColorSubject.asObservable();
  private fruitMaturitySubject = new ReplaySubject(1);
  private fruitMaturityObservable$ = this.fruitMaturitySubject.asObservable();
  private departmentSubject = new ReplaySubject(1);
  private departmentObservable$ = this.departmentSubject.asObservable();

  constructor(private translateService: TranslateService) {
    this.init();

    this.translateService.onLangChange.subscribe(() => {
      this.init();
    });
  }

  getMenuOrientations(): Observable<any> {
    return this.menuOrientationsObservable;
  }

  getLanguages(): Observable<any> {
    return this.languagesObservable;
  }

  getLifetimes(): Observable<any> {
    return this.lifetimeObservable;
  }

  getStands(): Observable<any> {
    return this.standObservable;
  }

  getFertilizations(): Observable<any> {
    return this.fertilizationObservable;
  }

  getUsages(): Observable<any> {
    return this.usageObservable;
  }

  getProductCategories(): Observable<any> {
    return this.productCategoryObservable$;
  }

  getProductTypes(): Observable<any> {
    return this.productTypesObservable$;
  }

  getTrademarks(): Observable<any> {
    return this.trademarkObservable$;
  }

  getSpiciness(): Observable<any> {
    return this.spicinessObservable$;
  }

  getFlowerPatterns(): Observable<any> {
    return this.flowerpatternObservable$;
  }

  getFlowerPeriods(): Observable<any> {
    return this.flowerperiodObservable$;
  }

  getFlowerMonths(): Observable<any> {
    return this.flowermonthObservable$;
  }

  getFruitColors(): Observable<any> {
    return this.fruitColorObservable$;
  }

  getFruitShapes(): Observable<any> {
    return this.fruitShapeObservable$;
  }

  getFruitMaturities(): Observable<any> {
    return this.fruitMaturityObservable$;
  }

  getDepartments(): Observable<any> {
    return this.departmentObservable$;
  }

  getNewsTypes(): Observable<any> {
    return this.newsTypeObservable$;
  }

  getActions(): Observable<any> {
    return this.actionObservable$;
  }

  private init(): void {
    this.initMenuOrientations();
    this.initLanguages();
    this.initStands();
    this.initLifetimes();
    this.initFertilizations();
    this.initUsages();
    this.initProductCategories();
    this.initActions();
    this.initProductTypes();
    this.initTrademarks();
    this.initFlowerPeriods();
    this.initFlowerMonths();
    this.initFlowerPatterns();
    this.initFruitColors();
    this.initFruitMaturities();
    this.initFruitShapes();
    this.initDepartments();
    this.initNewsTypes();
    this.initSpiciness();
  }

  private initMenuOrientations() {
    this.menuOrientationsSubject.next([
      {label: this.translateService.instant('enum.menuOrientation.STATIC'), value: MenuOrientation.STATIC},
      {label: this.translateService.instant('enum.menuOrientation.OVERLAY'), value: MenuOrientation.OVERLAY},
      {label: this.translateService.instant('enum.menuOrientation.HORIZONTAL'), value: MenuOrientation.HORIZONTAL},
    ]);
  }

  private initLanguages() {
    this.languagesSubject.next([
      {label: this.translateService.instant('enum.languages.NL'), value: Language.NL},
      {label: this.translateService.instant('enum.languages.FR'), value: Language.FR},
      {label: this.translateService.instant('enum.languages.EN'), value: Language.EN},
      {label: this.translateService.instant('enum.languages.DE'), value: Language.DE},
      {label: this.translateService.instant('enum.languages.PL'), value: Language.PL},
      {label: this.translateService.instant('enum.languages.HU'), value: Language.HU},
    ]);
  }

  private initLifetimes() {
    this.lifetimeSubject.next([
      {label: this.translateService.instant('enum.lifetime.ANNUAL'), value: Lifetime.ANNUAL},
      {label: this.translateService.instant('enum.lifetime.BIENNIAL'), value: Lifetime.BIENNIAL},
      {label: this.translateService.instant('enum.lifetime.PERENNIAL'), value: Lifetime.PERENNIAL}
    ]);
  }

  private initStands() {
    this.standSubject.next([
      {label: this.translateService.instant('enum.stand.HALFSHADOW'), value: Stand.HALF_SHADOW},
      {label: this.translateService.instant('enum.stand.SHADOW'), value: Stand.SHADOW},
      {label: this.translateService.instant('enum.stand.SUN'), value: Stand.SUN}
    ]);
  }


  private initFertilizations() {
    this.fertilizationSubject.next([
      {label: this.translateService.instant('enum.fertilization.LOW'), value: Fertilization.LOW},
      {label: this.translateService.instant('enum.fertilization.INTERMEDIATE'), value: Fertilization.INTERMEDIATE},
      {label: this.translateService.instant('enum.fertilization.HIGH'), value: Fertilization.HIGH}
    ]);
  }


  private initUsages() {
    this.usageSubject.next([
      {label: this.translateService.instant('enum.usage.BASKET_PLANT'), value: Usage.BASKET_PLANT},
      {label: this.translateService.instant('enum.usage.BEDDING_PLANT'), value: Usage.BEDDING_PLANT},
      {label: this.translateService.instant('enum.usage.SUITED_FOR_HANGING_BASKET'), value: Usage.SUITED_FOR_HANGING_BASKET},
      {label: this.translateService.instant('enum.usage.POT_PLANT'), value: Usage.POT_PLANT},
      {label: this.translateService.instant('enum.usage.CUT_FLOWER'), value: Usage.CUT_FLOWER}
    ]);
  }

  private initProductTypes(): void {
    this.productTypeSubject.next([
      {label: this.translateService.instant('enum.productType.SEEDS'), value: ProductType.SEEDS},
      {label: this.translateService.instant('enum.productType.SEEDLINGS'), value: ProductType.SEEDLINGS},
      {label: this.translateService.instant('enum.productType.CUTTINGS'), value: ProductType.CUTTINGS},
      {label: this.translateService.instant('enum.productType.TISSUECULTURE'), value: ProductType.TISSUECULTURE},
      {label: this.translateService.instant('enum.productType.SPLIT'), value: ProductType.SPLIT}
    ]);
  }

  private initProductCategories(): void {
    this.productCategorySubject.next([
      {label: this.translateService.instant('enum.productCategory.GENERAL'), value: ProductCategory.GENERAL},
      {label: this.translateService.instant('enum.productCategory.GROWEAT'), value: ProductCategory.GROWEAT},
      {label: this.translateService.instant('enum.productCategory.GRASSES'), value: ProductCategory.GRASSES},
      {label: this.translateService.instant('enum.productCategory.SASSY'), value: ProductCategory.SASSY},
      {label: this.translateService.instant('enum.productCategory.FOLIAGE'), value: ProductCategory.FOLIAGE},
      {label: this.translateService.instant('enum.productCategory.TRIO'), value: ProductCategory.TRIO}
    ]);
  }

  private initActions(): void {
    this.actionSubject.next([
      {label: this.translateService.instant('enum.action.NEEDS_TRANSLATIONS'), value: Action.NEEDS_TRANSLATIONS},
      {label: this.translateService.instant('enum.action.NEEDS_MORE_INFO'), value: Action.NEEDS_MORE_INFO}
    ]);
  }

  private initTrademarks(): void {
    this.trademarkSubject.next([
      {label: this.translateService.instant('enum.trademark.REGISTERED'), value: Trademark.REGISTERED},
      {label: this.translateService.instant('enum.trademark.UNREGISTERED'), value: Trademark.UNREGISTERED}
    ]);
  }

  private initFlowerPeriods(): void {
    this.flowerperiodSubject.next([
      {label: this.translateService.instant('enum.flowerperiod.EARLY'), value: Flowerperiod.EARLY},
      {label: this.translateService.instant('enum.flowerperiod.LATE'), value: Flowerperiod.LATE},
      {label: this.translateService.instant('enum.flowerperiod.OTHER'), value: Flowerperiod.OTHER}
    ]);
  }

  private initFlowerMonths(): void {
    this.flowermonthSubject.next([
      {label: this.translateService.instant('enum.flowermonth.JANUARY'), value: FlowerMonth.JANUARY},
      {label: this.translateService.instant('enum.flowermonth.FEBRUARY'), value: FlowerMonth.FEBRUARY},
      {label: this.translateService.instant('enum.flowermonth.MARCH'), value: FlowerMonth.MARCH},
      {label: this.translateService.instant('enum.flowermonth.APRIL'), value: FlowerMonth.APRIL},
      {label: this.translateService.instant('enum.flowermonth.MAY'), value: FlowerMonth.MAY},
      {label: this.translateService.instant('enum.flowermonth.JUNE'), value: FlowerMonth.JUNE},
      {label: this.translateService.instant('enum.flowermonth.JULY'), value: FlowerMonth.JULY},
      {label: this.translateService.instant('enum.flowermonth.AUGUST'), value: FlowerMonth.AUGUST},
      {label: this.translateService.instant('enum.flowermonth.SEPTEMBER'), value: FlowerMonth.SEPTEMBER},
      {label: this.translateService.instant('enum.flowermonth.OCTOBER'), value: FlowerMonth.OCTOBER},
      {label: this.translateService.instant('enum.flowermonth.NOVEMBER'), value: FlowerMonth.NOVEMBER},
      {label: this.translateService.instant('enum.flowermonth.DECEMBER'), value: FlowerMonth.DECEMBER}
    ]);
  }

  private initFlowerPatterns(): void {
    this.flowerpatternSubject.next([
      {label: this.translateService.instant('enum.flowerpattern.WITH_EYE'), value: Flowerpattern.WITH_EYE},
      {label: this.translateService.instant('enum.flowerpattern.SPOTTED'), value: Flowerpattern.SPOTTED},
      {label: this.translateService.instant('enum.flowerpattern.STRIPED'), value: Flowerpattern.STRIPED},
      {label: this.translateService.instant('enum.flowerpattern.VEINED'), value: Flowerpattern.VEINED},
      {label: this.translateService.instant('enum.flowerpattern.FLAMED'), value: Flowerpattern.FLAMED},
      {label: this.translateService.instant('enum.flowerpattern.PICOTEE'), value: Flowerpattern.PICOTEE},
      {label: this.translateService.instant('enum.flowerpattern.MONOCHROMATIC'), value: Flowerpattern.MONOCHROMATIC},
      {label: this.translateService.instant('enum.flowerpattern.DICHROMATIC'), value: Flowerpattern.DICHROMATIC}
    ]);
  }

  private initFruitMaturities(): void {
    this.fruitMaturitySubject.next([
      {label: this.translateService.instant('enum.fruitmaturity.IMMATURE'), value: FruitMaturity.IMMATURE},
      {label: this.translateService.instant('enum.fruitmaturity.MATURE'), value: FruitMaturity.MATURE},
      {label: this.translateService.instant('enum.fruitmaturity.MATURING'), value: FruitMaturity.MATURING}
    ]);
  }

  private initFruitColors(): void {
    this.fruitColorSubject.next([
      {label: this.translateService.instant('enum.fruitcolor.BI_COLOR'), value: FruitColor.BI_COLOR},
      {label: this.translateService.instant('enum.fruitcolor.BLACK'), value: FruitColor.BLACK},
      {label: this.translateService.instant('enum.fruitcolor.GREEN'), value: FruitColor.GREEN},
      {label: this.translateService.instant('enum.fruitcolor.ORANGE'), value: FruitColor.ORANGE},
      {label: this.translateService.instant('enum.fruitcolor.PURPLE'), value: FruitColor.PURPLE},
      {label: this.translateService.instant('enum.fruitcolor.RED'), value: FruitColor.RED},
      {label: this.translateService.instant('enum.fruitcolor.ROSE'), value: FruitColor.ROSE},
      {label: this.translateService.instant('enum.fruitcolor.WHITE'), value: FruitColor.WHITE},
      {label: this.translateService.instant('enum.fruitcolor.SPECIALS'), value: FruitColor.SPECIALS},
      {label: this.translateService.instant('enum.fruitcolor.YELLOW'), value: FruitColor.YELLOW}
    ]);
  }

  private initFruitShapes(): void {
    this.fruitShapeSubject.next([
      {label: this.translateService.instant('enum.fruitshape.COCKTAIL'), value: FruitShape.COCKTAIL},
      {label: this.translateService.instant('enum.fruitshape.LARGE_ROUND_FRUITS'), value: FruitShape.LARGE_ROUND_FRUITS},
      {label: this.translateService.instant('enum.fruitshape.HORN_SHAPED'), value: FruitShape.HORN_SHAPED},
      {label: this.translateService.instant('enum.fruitshape.HEART_SHAPED'), value: FruitShape.HEART_SHAPED},
      {label: this.translateService.instant('enum.fruitshape.OLIVE_SHAPED'), value: FruitShape.OLIVE_SHAPED},
      {label: this.translateService.instant('enum.fruitshape.ROUND_FRUITS'), value: FruitShape.ROUND_FRUITS},
      {label: this.translateService.instant('enum.fruitshape.MARMANDE'), value: FruitShape.MARMANDE},
      {label: this.translateService.instant('enum.fruitshape.COEUR_DE_BOEUF'), value: FruitShape.COEUR_DE_BOEUF},
      {label: this.translateService.instant('enum.fruitshape.TRUSS_TOMATOES'), value: FruitShape.TRUSS_TOMATOES},
      {label: this.translateService.instant('enum.fruitshape.BLOCKY'), value: FruitShape.BLOCKY},
      {label: this.translateService.instant('enum.fruitshape.LONG_POINT'), value: FruitShape.LONG_POINT},
      {label: this.translateService.instant('enum.fruitshape.SPECIALS'), value: FruitShape.SPECIALS},
      {label: this.translateService.instant('enum.fruitshape.LIGURIA'), value: FruitShape.LIGURIA}
    ]);
  }

  private initDepartments(): void {
    this.departmentSubject.next([
      {label: this.translateService.instant('enum.department.INFO_DEPARTMENT'), value: Department.INFO_DEPARTMENT},
      {label: this.translateService.instant('enum.department.CUSTOMER_DEPARTMENT'), value: Department.CUSTOMER_DEPARTMENT},
      {label: this.translateService.instant('enum.department.PLANTS_ORDER'), value: Department.PLANTS_ORDER},
      {label: this.translateService.instant('enum.department.SEEDS_ORDER'), value: Department.SEEDS_ORDER}
    ]);
  }

  private initNewsTypes(): void {
    this.newsTypeSubject.next([
      {label: this.translateService.instant('enum.newsType.VIDEO'), value: NewsType.VIDEO},
      {label: this.translateService.instant('enum.newsType.ARTICLE'), value: NewsType.ARTICLE},
      {label: this.translateService.instant('enum.newsType.CASESTUDY'), value: NewsType.CASESTUDY},
      {label: this.translateService.instant('enum.newsType.CATALOG'), value: NewsType.CATALOG}
    ]);
  }

  private initSpiciness(): void {
    this.spicinessSubject.next([
      {label: this.translateService.instant('enum.spiciness.HOT'), value: Spiciness.HOT},
      {label: this.translateService.instant('enum.spiciness.EXTRA_HOT'), value: Spiciness.EXTRA_HOT},
      {label: this.translateService.instant('enum.spiciness.MILD'), value: Spiciness.MILD},
      {label: this.translateService.instant('enum.spiciness.MEDIUM'), value: Spiciness.MEDIUM}
    ]);
  }
}

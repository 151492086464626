import {Component, OnInit} from '@angular/core';
import {LayoutService} from './service/app.layout.service';
import {appRoutesLinks} from '@app/app.routes.links';
import {Router} from '@angular/router';
import {build} from '@config/build-information';

@Component({
  selector: 'app-footer',
  templateUrl: './app.footer.component.html'
})
export class AppFooterComponent implements OnInit {
  buildInfo: string;

  constructor(public layoutService: LayoutService, private router: Router) {
  }

  ngOnInit(): void {
    this.buildInfo = 'v' + build.version + ' #' + build.revision;
  }

  navigateToDashboard() {
    this.router.navigate(appRoutesLinks.HOME);
  }

  navigateToWebsite() {
    window.location.href = 'https://www.raes.be';
  }

  navigateToFacebook() {
    window.location.href = 'https://www.facebook.com/raesbloemzaden';
  }
}

import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { MegaMenuItem } from 'primeng/api';
import { LayoutService } from './service/app.layout.service';
import {AppConfigService} from '@core/providers/app-config.service';
import {CurrentUserService} from '@core/services/current-user.service';
import {AuthenticationService} from '@core/services/authentication.service';
import {Router} from '@angular/router';
import {UserProfile} from '@shared/model/auth/user-profile';
import {appRoutesLinks} from '@app/app.routes.links';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html'
})
export class AppTopbarComponent implements OnInit {

  constructor(public layoutService: LayoutService, private appConfig: AppConfigService, private router: Router,
              private currentUserService: CurrentUserService,
              private authService: AuthenticationService,
              public el: ElementRef) {
    this.currentUserService.getUser().subscribe(profile => {
      this.currentUser = profile;
    });
  }

  get mobileTopbarActive(): boolean {
    return this.layoutService.state.topbarMenuActive;
  }

  env: string;
  activeItem!: number;
  currentUser: UserProfile;

  @ViewChild('menuButton') menuButton!: ElementRef;

  @ViewChild('mobileMenuButton') mobileMenuButton!: ElementRef;

  @ViewChild('searchInput') searchInput!: ElementRef;

  model: MegaMenuItem[] = [
    {
      label: 'Taxonomie',
      items: [
        [
          {
            label: 'Genus',
            items: [
              {label: 'Overzicht', icon: 'pi pi-fw pi-id-card', routerLink: ['/taxonomy/genus']},
              {label: 'Aanmaken', icon: 'pi pi-fw pi-check-square', routerLink: ['/taxonomy/genus/create']}
            ]
          }
        ],
        [
          {
            label: 'Specie',
            items: [
              {label: 'Overzicht', icon: 'pi pi-fw pi-id-card', routerLink: ['/taxonomy/specie']},
              {label: 'Aanmaken', icon: 'pi pi-fw pi-check-square', routerLink: ['/taxonomy/specie/create']}
            ]
          }
        ],
        [
          {
            label: 'Selected Cultivar',
            items: [
              {label: 'Overzicht', icon: 'pi pi-fw pi-id-card', routerLink: ['/taxonomy/selectedcultivar']},
              {label: 'Aanmaken', icon: 'pi pi-fw pi-check-square', routerLink: ['/taxonomy/selectedcultivar/create']}
            ]
          }
        ],
        [
          {
            label: 'Plant',
            items: [
              {label: 'Overzicht', icon: 'pi pi-fw pi-id-card', routerLink: ['/taxonomy/plant']},
              {label: 'Aanmaken', icon: 'pi pi-fw pi-check-square', routerLink: ['/taxonomy/plant/create']}
            ]
          }
        ]
      ]
    },
    {
      label: 'E-Commerce',
      items: [
        [
          {
            label: 'Klanten',
            items: [
              {label: 'Overzicht', icon: 'pi pi-fw pi-prime', routerLink: ['utilities/icons']},
              {label: 'Aanmaken', icon: 'pi pi-fw pi-desktop', url: 'https://www.primefaces.org/primeflex/', target: '_blank'}
            ]
          }
        ],

      ]
    }
  ];

  ngOnInit(): void {
    this.env = this.appConfig.config().identifier;
    console.log('current env ' + this.env);
  }

  onMenuButtonClick() {
    this.layoutService.onMenuToggle();
  }

  onRightMenuButtonClick() {
    this.layoutService.openRightSidebar();
  }

  onMobileTopbarMenuButtonClick() {
    this.layoutService.onTopbarMenuToggle();
  }

  focusSearchInput() {
    setTimeout(() => {
      this.searchInput.nativeElement.focus();
    }, 0);
  }

  navigateToProfile() {
    this.router.navigate(appRoutesLinks.ACCOUNT_PROFILE);
  }

  logout() {
    this.authService.logout();
    this.router.navigate(appRoutesLinks.LOGIN);
  }
}

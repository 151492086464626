<div class="layout-container" [ngClass]="containerClass">

    <app-topbar></app-topbar>
    <app-sidebar></app-sidebar>

    <div class="layout-content-wrapper">
        <app-breadcrumb></app-breadcrumb>
        <p-toast></p-toast>
        <div class="layout-content">
            <router-outlet></router-outlet>
        </div>

        <app-footer></app-footer>
    </div>
    <app-config></app-config>

    <div *ngIf="layoutService.state.staticMenuMobileActive" class="layout-mask"></div>
</div>

import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AppConfigService} from "@core/providers/app-config.service";
import {Observable} from "rxjs";
import {Genus} from "@shared/model/genus";
import {map} from "rxjs/operators";
import {Disease} from "@shared/model/disease";
import {Soil} from "@shared/model/soil";
import {Color} from "@shared/model/color";

@Injectable({
  providedIn: 'root'
})
export class PropertiesService {
  private readonly _publicApi: string;
  private readonly _privateApi: string;

  constructor(private http: HttpClient, private appConfig: AppConfigService) {
    this._publicApi = this.appConfig.config().apiBase;
    this._privateApi = this.appConfig.config().privateApiBase;
  }

  retrieveDiseases(): Observable<Disease[]> {
  const diseaseUrl = `${this._privateApi}/diseases`;
  return this.http.get<any>(diseaseUrl).pipe(
    map(response => response.content.map(Disease.adapt))
  );
}

  retrieveSoils(): Observable<Soil[]> {
    const soilUrl = `${this._privateApi}/soils`;
    return this.http.get<any>(soilUrl).pipe(
      map(response => response.content.map(Soil.adapt))
    );
  }

  retrieveColors(): Observable<Color[]> {
    const colorUrl = `${this._privateApi}/colors`;
    return this.http.get<any>(colorUrl).pipe(
      map(response => response.content.map(Color.adapt))
    );
  }
}

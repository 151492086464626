import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-taxonomy-translations',
  templateUrl: './taxonomy-translations.component.html',
  styleUrls: ['./taxonomy-translations.component.css']
})
export class TaxonomyTranslationsComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() editMode: false;

  translateCriteria = new FormGroup({
    translatableDescription: new FormControl(),
    sourceLanguage: new FormControl('EN')
  });
  reloadSuggestions = new EventEmitter<string>();


  constructor() { }

  ngOnInit(): void {
    this.translateCriteria.controls.translatableDescription.valueChanges.subscribe(text => {
      this.reloadSuggestions.next(text);
    });

    this.translateCriteria.controls.sourceLanguage.valueChanges.subscribe(language => {
      this.reloadSuggestions.next(this.translateCriteria.controls.translatableDescription.value);
    });
  }

}

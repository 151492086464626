import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from '@core/services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private authService: AuthenticationService) { }

  ngOnInit(): void {
  }

  login(): void {
    this.authService.attemptLogin('/');
  }

}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from '@core/providers/app-config.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TranslationsService {
  private readonly _api: string;

  constructor(private http: HttpClient, private appConfig: AppConfigService) {
    this._api = this.appConfig.config().privateApiBase + '/translations';
  }

  initiateTranslation(plantId: number): Observable<any> {
    const plantUrl = `${this._api}/plant/${plantId}/initiate`;
    return this.http.put(plantUrl, {});
  }

  suggest(text: string, targetLanguage: string, sourceLanguage: string = ''): Observable<string> {
    let translationsUrl = `${this._api}/suggest?text=${text}&targetLanguage=${targetLanguage}`;

    if (sourceLanguage) {
      translationsUrl = `${translationsUrl}&sourceLanguage=${sourceLanguage}`;
    }

    return this.http.get<any>(translationsUrl).pipe(
      map(result => result.suggestion)
    );
  }
}
